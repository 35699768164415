<template>
  <v-container class="contentContainer">
    <!-- PAGE BANNER - Feedback Title Section -->
    <PageHeader class="pb-4" heading="About Us"/>
    <!-- MAIN CONTENT AREA - Content Section -->
    <v-row justify="center">
      <v-col class="miniCol text-center px-0 py-sm-12 mb-12">
        <v-icon color="accent" size="65" class="mb-8">mdi-book-open-page-variant</v-icon>
        <p class="text-h5 text-sm-h4 pr-4 font-weight-regular"><strong class="accent--text">UofT Index</strong> strives to
          help <strong class="accent--text">students</strong> make informed decisions, one course
          at a time, by providing the <strong class="accent--text">information</strong> needed to do so.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center" class="border storySection">
      <v-col class="contentMaxWidth my-12">
        <p class="text-h4 font-weight-medium text-center mb-12">Our Story</p>
        <p class="storyText">Founded in 2022, UofT Index is a student-built and student-run passion project designed
          for the UofT community. What started as an idea to improve how students find course prerequisites after two
          years of procrastination and one year of development has resulted in UofT Index. With its launching, we hope
          to address a real need of UofT students. Chiefly, the desire for better insight, information, and tools
          related to courses and enrolment.</p>
        <p class="storyText">Dedicated to being the one-stop shop for UofT students when course enrolment rolls
          around, we are continuously looking for ways to improve and enhance the services we provide. While not
          officially affiliated with the University of Toronto or related identities, we endeavour to provide an
          experience that is second to none. Among other things, that includes an ad-free environment, clean and
          user-friendly interface, and access to a wealth of helpful course, professor, and campus information.</p>
        <p class="storyText">As we continue to evolve and improve UofT Index, one thing that has and will always
          remain constant is our commitment to the UofT community and providing the information and tools needed to
          help you succeed. </p>
        <p class="storyText">Thanks for checking out UofT Index!</p>
        <p class="storyText">~ Your UofT Index Team</p>
      </v-col>
    </v-row>
    <v-row class="mt-12" justify="center">
      <v-col class="contentMaxWidth mb-12">
        <p class="text-h4 font-weight-medium mb-12 text-center">Meet the Team</p>
        <v-row justify="center">
          <v-col cols="12" class="col-sm-6 my-5 px-1 px-sm-3">
            <v-row>
              <v-col cols="12" class="col-md-6 pr-md-0 text-center">
                <img src="../assets/justin.jpeg" class="mt-2">
              </v-col>
              <v-col cols="12" class="col-md-6 pl-md-0">
                <p class="text-h5 font-weight-medium mb-0 accent--text">Justin Lyn</p>
                <p class="text-body-1 mb-2 text--secondary">Founder</p>
                <v-row class="my-1 mx-0">
                  <v-icon class="mr-3" color="accent" @click="externalNav('https://www.linkedin.com/in/justin-lyn/')">mdi-linkedin</v-icon>
                  <v-icon color="error" @click="externalNav('https://imjustinlyn.web.app/')">mdi-web</v-icon>
                </v-row>
                <v-divider class="my-4"/>
                <p>
                  Spends his time watching K-dramas, coding, and designing new UIXs. He loves learning new technologies
                  and concepts, and building things people can use and share with others.
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="col-sm-6 my-5 px-1 px-sm-3">
            <v-row>
              <v-col cols="12" class="col-md-6 pr-md-0 text-center">
                <img src="../assets/bilal.jpeg" class="mt-2">
              </v-col>
              <v-col cols="12" class="col-md-6 pl-md-0">
                <p class="text-h5 font-weight-medium mb-0 accent--text">Bilal Ahmed</p>
                <p class="text-body-1 mb-2 text--secondary">Co-Founder</p>
                <v-row class="my-1 mx-0">
                  <v-icon class="mr-3" color="accent" @click="externalNav('https://www.linkedin.com/in/bill-ahmed/')">mdi-linkedin</v-icon>
                  <v-icon color="error" @click="externalNav('https://billahmed.com/')">mdi-web</v-icon>
                </v-row>
                <v-divider class="my-4"/>
                <p>
                  When he's not consuming sci-fi or video games, you'll catch him coding and experimenting
                  with technology. He's always curious about finding new ways to make an impact.
                </p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader'

export default {
  name: 'About',
  components: { PageHeader },
  data: () => ({}),
  methods: {
    externalNav (link) { window.open(link, '_blank').focus() }
  }
}
</script>

<style scoped>
  img {
    max-width: 200px;
    height: auto;
    width: auto\9;
    image-orientation: from-image;
    border-radius: 50%;
  }
  .miniCol {
    max-width: 800px;
  }
  .storySection {
    margin: 0 -46px;
    padding: 0 22px;
  }
  .storyText {
    font-size: 17px !important;
    line-height: inherit;
    margin-top: 30px;
  }

</style>
